@media screen and (max-width: 600px) {
  #question-section-container {
    padding: 1em 0 !important;
    align-content: center !important;
    padding: 0 !important;
  }

  #text-container-full {
    height: 140px !important;
  }

  #question-text {
    padding: 10px !important;
    padding-bottom: 0px !important;
    font-size: 15px !important;
  }

  #text-container {
    flex-direction: column !important;
  }

  #question-checkbox-container {
    padding: 10px !important;
  }
}

@media screen and (max-width: 1007px) {
  #submit-button {
    width: 100% !important;
  }
}
