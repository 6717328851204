@media screen and (max-width: 640px) {
  .question-category-icon {
    display: none !important;
  }

  #category-container {
    display: block !important;
  }
  #icon-question-wrapper {
    display: flex !important;
  }

  #take-quiz-button {
    text-align: end;
  }

  #category-section-heading-text {
    margin: 1rem 0rem !important;
  }
}

@media screen and (max-width: 1007px) {
  #category-section-heading-text {
    max-width: 450px;
    margin: 0 auto;
  }
}
