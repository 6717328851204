.user-details-input {
  width: 100%;
  box-sizing: border-box;
  border-radius: 0px;
}

.user-details-input-box {
  margin: 0.5em 0;
  width: 100%;
}

.user-details-label {
  font-size: 13px;
}

.role-type-input {
  width: 100%;
  font-family: "Futura", "Futura Medium", sans-serif !important;
  font-weight: 500 !important;
}

.loadingMessage {
  width: 100%;
  text-align: center;
  margin-top: 0.5rem;
}

.Mui-disabled {
  background: #e0e0e0 !important;
  color: #9e9e9e !important;
}

@media screen and (max-width: 600px) {
  #form-section-container {
    width: 100% !important;
    padding: 0 !important;
  }
}
