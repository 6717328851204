@font-face {
  font-family: "Futura Medium";
  src: local("Futura Medium"), url("./futura-medium.otf");
}

@font-face {
  font-family: "Futura LT";
  src: url("./futura-lt-heavy.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}
