.Mui-active,
.Mui-completed {
  color: #1a1363 !important;
}

.MuiStepLabel-label,
.MuiMenuItem-root,
.MuiButton-root {
  font-family: "Futura", "Futura Medium", sans-serif !important;
}

/*
  Color palette:
  Light purple #814fa0
  Blue #5f92c6
  Mint #5fc19f

  Yellow #eeb32f
  Orange #e94b25

  Teal #38aca7
  Dark purple #1a1363

  Font: futura
*/
