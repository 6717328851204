input[type="radio"][id="custom-radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #1a1363;
  border-radius: 20%;
  background-clip: content-box;
  padding: 3px;
  margin-right: 0.5em;
}

input[type="radio"][id="custom-radio"]:checked {
  background-color: #1a1363;
}
