@media screen and (max-width: 600px) {
  #header-heading {
    font-size: 40px !important;
    margin-top: 20px;
  }

  #header-subheading {
    font-size: 25px !important;
  }
}
