@media screen and (max-width: 600px) {
  #score-section-container {
    padding: 0 !important;
  }

  .score-section-button-container {
    flex-direction: column;
    row-gap: 5px;
    align-items: center;
  }

  .score-section-button {
    width: 300px;
  }
}
